import * as React from "react";
import {Button, Dialog, DialogContent, Divider, Grid, Paper, Typography, useMediaQuery,} from "@mui/material";
import Menu from "../../components/ui/Menu";
import useLabels from "../../labels/useLabels";
import {useNavigate} from "react-router-dom";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import PasswordIcon from "@mui/icons-material/Password";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileOption from "./components/ProfileOption";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LanguageIcon from "@mui/icons-material/Language";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PageMenuOption from "../../components/PageMenuOption";
import {styled} from "@mui/system";
import HttpService from "../../services/HttpService";
import FeaturesService from "../../services/config/FeaturesService";
import MainBackground from "../../components/ui/MainBackground";
import CustomerService from "../../services/CustomerService";
import {formatPhoneNumber} from "./FormatUtils";

export default function Profile() {
  const { getLabel } = useLabels();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const {
    isLoading: featuresServiceIsLoading,
    isError: featuresServiceIsError,
    features,
    error: featuresServiceError,
  } = FeaturesService();
  const customerService = CustomerService();

  const [customerServiceInfoVisible, setCustomerServiceInfoVisible] =
    React.useState(false);

  const [htmlContent, setHtmlContent] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const showCustomerServiceInfo = (event, key) => {
    setCustomerServiceInfoVisible(true);
    event.preventDefault();
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") setOpenDialog(false);
  };

  const StyledLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }));

  const dialogUI = (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {getLabel("cardActivation.btnClose", "Close")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const handleViewPrivacyPolicyClick = (event) => {
    HttpService.getAxiosClient()
      .get(window.API_URL + `/document/privacyPolicy_en/getHTML`)
      .then((response) => {
        setHtmlContent(response.data);
        setOpenDialog(true);
      })
      .catch((error) => {});

    event.preventDefault();
  };

  var customerServicePhone =
    customerService?.customerInfo?.additionalInfo?.["customer.service.phone"];

  var customerServiceEmail =
    customerService?.customerInfo?.additionalInfo?.["customer.service.email"];

  return (
    <div style={{ display: true ? "block" : "none" }}>
      <MainBackground
        isMobile={isMobile}
        component="form"
        noValidate
        autoComplete="off"
      >
        <Grid
          container
          style={{
            width: isMobile ? "100%" : "65%",
            margin: !isMobile ? "3rem auto" : 0,
          }}
        >
          {dialogUI}

          <Grid item xs={12} p={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccountCircleIcon
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  paddingRight: "1rem",
                })}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {getLabel("profile.title", "Profile")}
              </Typography>
            </div>
          </Grid>

          <PageMenuOption
            isMobile={isMobile}
            navigateTo="/changePassword"
            label="profile.changePassword"
            defaultLabel="Change Password"
            icon={
              <PasswordIcon sx={{ fontSize: "1.2rem", paddingLeft: "1rem" }} />
            }
          />
          {features?.SETUP_ALERTS ? (
            <PageMenuOption
              isMobile={isMobile}
              navigateTo="/config/notifications"
              label="profile.configureNotifications"
              defaultLabel="Configure Notifications"
              icon={
                <EditNotificationsIcon
                  sx={{ fontSize: "1.2rem", paddingLeft: "1rem" }}
                />
              }
            />
          ) : (
            ""
          )}

          <PageMenuOption
            isMobile={isMobile}
            navigateTo="/changeLanguage"
            label="profile.changeLanguage"
            defaultLabel="Change Language"
            icon={
              <LanguageIcon sx={{ fontSize: "1.2rem", paddingLeft: "1rem" }} />
            }
          />

          <PageMenuOption
            isMobile={isMobile}
            //navigateTo="/privacyPolicy"
            callToAction={(event) => handleViewPrivacyPolicyClick(event)}
            label="profile.privacyPolicy"
            defaultLabel="Privacy Policy"
            icon={
              <PrivacyTipIcon
                sx={{ fontSize: "1.2rem", paddingLeft: "1rem" }}
              />
            }
          />

          <Grid
            container
            pt={2}
            spacing={2}
            justifyContent="center"
            marginTop="2rem"
          >
            <ProfileOption
              isMobile={isMobile}
              navigateTo="/accountInfo"
              label="profile.accountInfo"
              defaultLabel="Account Info"
              icon={<PersonIcon />}
            />
            <ProfileOption
              isMobile={isMobile}
              callToAction={(event) => showCustomerServiceInfo(event)}
              label="profile.customerService"
              defaultLabel="Customer Service"
              icon={<SupportAgentIcon />}
            />
            <ProfileOption
              isMobile={isMobile}
              navigateTo="/cardAdmin"
              label="profile.cardAdmin"
              defaultLabel="Card Admin"
              icon={<CreditCardIcon />}
            />
          </Grid>
          {customerServiceInfoVisible ? (
            <div
              id="cusser"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minWidth: "100%",
              }}
            >
              <Paper
                elevation={2}
                sx={{
                  margin: "0.5rem",
                  height: "100px",
                  borderRadius: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "2rem",
                  backgroundColor: "rgba(255, 255, 255, 0.25)",
                }}
              >
                <Grid
                  item
                  xs={12}
                  p={1}
                  sx={{ display: features?.HIDE_PHONE ? "none" : "block" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CallIcon
                      sx={{ fontSize: "1.5rem", paddingRight: "1rem" }}
                    />

                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "10rem",
                      }}
                    >
                      {getLabel(
                        "profile.customerservice.callus",
                        "Call us at: "
                      )}
                      <StyledLink href={`tel:"+${customerServicePhone}`}>
                        {customerServicePhone &&
                          formatPhoneNumber(customerServicePhone)}
                      </StyledLink>
                    </Typography>
                  </div>
                </Grid>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ display: features?.HIDE_PHONE ? "none" : "block" }}
                />
                <Grid item xs={12} p={1}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <EmailIcon
                      sx={{ fontSize: "1.5rem", paddingRight: "1rem" }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "10rem",
                      }}
                    >
                      {getLabel(
                        "profile.customerservice.emailus",
                        "Email us at: "
                      )}
                      <StyledLink href={`mailto:${customerServiceEmail}`}>
                        {customerServiceEmail}
                      </StyledLink>
                    </Typography>
                  </div>
                </Grid>
              </Paper>
            </div>
          ) : null}
        </Grid>
      </MainBackground>
      <Menu isMobile={isMobile} />
    </div>
  );
}
